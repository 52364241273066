import { render, staticRenderFns } from "./askForLeave.vue?vue&type=template&id=2dcbc46b&scoped=true"
import script from "./askForLeave.vue?vue&type=script&lang=js"
export * from "./askForLeave.vue?vue&type=script&lang=js"
import style0 from "./askForLeave.vue?vue&type=style&index=0&id=2dcbc46b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dcbc46b",
  null
  
)

export default component.exports